import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import './style.scss';
import PageMenu from '../../../components/PageMenu/PageMenu';
import homeWarrantyResourcesMenu from '../../../data/page-menus/home-warranty-resources';

type Props = PageProps & WithTranslation;

const HomeWarrantyFAQ: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('faq.seo.title'),
    },
    page: {
      title: t('faq.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-14.jpg',
          alt: 'Image',
        },
        blockText: t('faq.tagline'),
        shouldBeDigiBlock: true,
      },
    },
  };

  const generateList = (
    list: any[],
    opts: { appendElements: any[]; position?: 'top' } = {
      appendElements: [],
      position: 'top',
    }
  ) => {
    return (
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {opts.position === 'top' && opts.appendElements}
        {list.map((item, i) => {
          return (
            <AccordionItem
              key={`${Math.random()}-${i}`}
              buttonOptions={{ label: item.question }}
              headingProps={{ 'aria-level': 2 }}
            >
              {/* eslint-disable-next-line react/no-danger */}
              {/* <p dangerouslySetInnerHTML={{ __html: item.answer }} /> */}
              {item.answerList ? (
                (item.answerList as { heading: string; body: string }[]).map((answerListItem) => (
                  <>
                    <h3 className="h6 accordion-item-heading">{answerListItem.heading}</h3>
                    <p>{answerListItem.body}</p>
                  </>
                ))
              ) : (
                <p>{item.answer}</p>
              )}
            </AccordionItem>
          );
        })}
        {opts.position !== 'top' && opts.appendElements}
      </Accordion>
    );
  };

  const generateStringList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          // eslint-disable-next-line react/no-danger
          return <li key={`${str}-${i}`}>{str}</li>;
        })}
      </ul>
    );
  };

  return (
    <Layout options={layoutOptions} className="HomeWarrantyFAQ">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            {generateList(t('faq.questions', { returnObjects: true }), {
              position: 'top',
              appendElements: [
                <AccordionItem
                  key="q-001"
                  buttonOptions={{ label: t('faq.lastQuestion.question') }}
                  headingProps={{ 'aria-level': 2 }}
                >
                  <p>{t('faq.lastQuestion.answer.description')}</p>
                  {generateStringList(t('faq.lastQuestion.answer.list', { returnObjects: true }))}
                  <p>{t('faq.lastQuestion.answer.footerParagraph')}</p>
                </AccordionItem>,
              ],
            })}
            <div>
              <h2>{t('faq.caseStudiesBlock.heading')}</h2>
              <p>
                <Trans i18nKey="faq.caseStudiesBlock.description" t={t} />
              </p>
            </div>
          </section>

          <aside className="column column-33">
            <PageMenu
              className="HomeWarranty__generic-gap"
              menu={homeWarrantyResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.homeWarrantyResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(HomeWarrantyFAQ);
